import validate from "/opt/build/repo/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45check_45global from "/opt/build/repo/client/middleware/authCheck.global.js";
import manifest_45route_45rule from "/opt/build/repo/client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45check_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "tenant-set": () => import("/opt/build/repo/client/middleware/tenantSet.js"),
  auth: () => import("/opt/build/repo/client/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}