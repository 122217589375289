export default {
  account: () => import("/opt/build/repo/client/layouts/account.vue").then(m => m.default || m),
  billing: () => import("/opt/build/repo/client/layouts/billing.vue").then(m => m.default || m),
  brand: () => import("/opt/build/repo/client/layouts/brand.vue").then(m => m.default || m),
  campaign: () => import("/opt/build/repo/client/layouts/campaign.vue").then(m => m.default || m),
  default: () => import("/opt/build/repo/client/layouts/default.vue").then(m => m.default || m),
  editor: () => import("/opt/build/repo/client/layouts/editor.vue").then(m => m.default || m),
  email: () => import("/opt/build/repo/client/layouts/email.vue").then(m => m.default || m),
  forms: () => import("/opt/build/repo/client/layouts/forms.vue").then(m => m.default || m),
  media: () => import("/opt/build/repo/client/layouts/media.vue").then(m => m.default || m),
  people: () => import("/opt/build/repo/client/layouts/people.vue").then(m => m.default || m),
  sell: () => import("/opt/build/repo/client/layouts/sell.vue").then(m => m.default || m),
  simple: () => import("/opt/build/repo/client/layouts/simple.vue").then(m => m.default || m),
  team: () => import("/opt/build/repo/client/layouts/team.vue").then(m => m.default || m),
  unauth: () => import("/opt/build/repo/client/layouts/unauth.vue").then(m => m.default || m),
  website: () => import("/opt/build/repo/client/layouts/website.vue").then(m => m.default || m)
}