export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      triggerClientFetch: (el: any) => {
        console.log("triggerClientFetch", el);
        const message = "triggerClientFetch";
        const iframe = document.querySelector(el);
        iframe.contentWindow.postMessage(message, "*");
      },
    },
  };
});
