import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/opt/build/repo/client/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import draggable_client_hyqucl8vfz from "/opt/build/repo/client/plugins/draggable.client.ts";
import filters_znakZuvLqA from "/opt/build/repo/client/plugins/filters.js";
import findUID_oLz6P8WkTW from "/opt/build/repo/client/plugins/findUID.js";
import konva_7dtTaXju5v from "/opt/build/repo/client/plugins/konva.js";
import oruga_2qeBGjDWKO from "/opt/build/repo/client/plugins/oruga.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/client/plugins/sentry.client.ts";
import socketio_client_urjK0strDo from "/opt/build/repo/client/plugins/socketio.client.ts";
import triggerClientFetch_client_pK2Cpvk9Cr from "/opt/build/repo/client/plugins/triggerClientFetch.client.ts";
import uuidv44_h13cztU26I from "/opt/build/repo/client/plugins/uuidv44.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  draggable_client_hyqucl8vfz,
  filters_znakZuvLqA,
  findUID_oLz6P8WkTW,
  konva_7dtTaXju5v,
  oruga_2qeBGjDWKO,
  sentry_client_shVUlIjFLk,
  socketio_client_urjK0strDo,
  triggerClientFetch_client_pK2Cpvk9Cr,
  uuidv44_h13cztU26I
]