import { default as indexcwKUbcUhhfMeta } from "/opt/build/repo/client/pages/-billing/index.vue?macro=true";
import { default as planYv588nLI3oMeta } from "/opt/build/repo/client/pages/-billing/plan.vue?macro=true";
import { default as successp0GkqQ6GWxMeta } from "/opt/build/repo/client/pages/-billing/success.vue?macro=true";
import { default as usagewBzfP0v3YXMeta } from "/opt/build/repo/client/pages/-billing/usage.vue?macro=true";
import { default as indexOCXqPnHnWrMeta } from "/opt/build/repo/client/pages/-people/index.vue?macro=true";
import { default as alldPPPfigWsnMeta } from "/opt/build/repo/client/pages/-team/all.vue?macro=true";
import { default as currentwwSc0xdm09Meta } from "/opt/build/repo/client/pages/-team/current.vue?macro=true";
import { default as indexj19Bqz7fOXMeta } from "/opt/build/repo/client/pages/-team/index.vue?macro=true";
import { default as manage2SziphBqTlMeta } from "/opt/build/repo/client/pages/-team/manage.vue?macro=true";
import { default as settingsCCifkfNZdgMeta } from "/opt/build/repo/client/pages/-team/settings.vue?macro=true";
import { default as change6U6jHUyeqbMeta } from "/opt/build/repo/client/pages/account/change.vue?macro=true";
import { default as indexb0ojb89cC6Meta } from "/opt/build/repo/client/pages/account/index.vue?macro=true";
import { default as planV7uQod850cMeta } from "/opt/build/repo/client/pages/account/plan.vue?macro=true";
import { default as settingss1S5lBnOMJMeta } from "/opt/build/repo/client/pages/account/settings.vue?macro=true";
import { default as accountqlXoB4whbsMeta } from "/opt/build/repo/client/pages/account.vue?macro=true";
import { default as backuphKGWLyf91LMeta } from "/opt/build/repo/client/pages/admin/backup.vue?macro=true";
import { default as indexFRnhX2U7hdMeta } from "/opt/build/repo/client/pages/admin/index.vue?macro=true";
import { default as themesI8hvcWdlf9Meta } from "/opt/build/repo/client/pages/admin/themes.vue?macro=true";
import { default as adminrLOo26hEJPMeta } from "/opt/build/repo/client/pages/admin.vue?macro=true";
import { default as assetsWyNiTxGg9ZMeta } from "/opt/build/repo/client/pages/brand/assets.vue?macro=true";
import { default as colorsTReW5nnNwgMeta } from "/opt/build/repo/client/pages/brand/colors.vue?macro=true";
import { default as designerKggrO0q7D4Meta } from "/opt/build/repo/client/pages/brand/designer.vue?macro=true";
import { default as fontsnXSkrzlzndMeta } from "/opt/build/repo/client/pages/brand/fonts.vue?macro=true";
import { default as iconsW7EXQIxAatMeta } from "/opt/build/repo/client/pages/brand/icons.vue?macro=true";
import { default as indexNzPhWMOeuBMeta } from "/opt/build/repo/client/pages/brand/index.vue?macro=true";
import { default as branduGDsjlwSMWMeta } from "/opt/build/repo/client/pages/brand.vue?macro=true";
import { default as chat8xHUQISi4JMeta } from "/opt/build/repo/client/pages/chat.vue?macro=true";
import { default as emailmvMEKklBCOMeta } from "/opt/build/repo/client/pages/email.vue?macro=true";
import { default as _91form_93H8uzfYQLdGMeta } from "/opt/build/repo/client/pages/forms/[form].vue?macro=true";
import { default as index1yI8AecO4fMeta } from "/opt/build/repo/client/pages/forms/index.vue?macro=true";
import { default as formsr0Bpms0npvMeta } from "/opt/build/repo/client/pages/forms.vue?macro=true";
import { default as indexjCwtMlDmpIMeta } from "/opt/build/repo/client/pages/index.vue?macro=true";
import { default as loginJmYpJqBwezMeta } from "/opt/build/repo/client/pages/login.vue?macro=true";
import { default as indexzOy8jhW4l4Meta } from "/opt/build/repo/client/pages/media/index.vue?macro=true";
import { default as mediaP4wElF4O4wMeta } from "/opt/build/repo/client/pages/media.vue?macro=true";
import { default as profileJkb97NfCvoMeta } from "/opt/build/repo/client/pages/profile.vue?macro=true";
import { default as registerVe2scXsUVUMeta } from "/opt/build/repo/client/pages/register.vue?macro=true";
import { default as _91reset_93igxrv9Zlc4Meta } from "/opt/build/repo/client/pages/reset/[reset].vue?macro=true";
import { default as index5Dbg7FMMRuMeta } from "/opt/build/repo/client/pages/reset/index.vue?macro=true";
import { default as indexoC7CPhMgfxMeta } from "/opt/build/repo/client/pages/sell/customers/index.vue?macro=true";
import { default as indexO1HEcleplvMeta } from "/opt/build/repo/client/pages/sell/index.vue?macro=true";
import { default as indexSi0DEnTmU3Meta } from "/opt/build/repo/client/pages/sell/payment/index.vue?macro=true";
import { default as indexUumDcCHraZMeta } from "/opt/build/repo/client/pages/sell/products/index.vue?macro=true";
import { default as indexLW9bVNmISUMeta } from "/opt/build/repo/client/pages/sell/transactions/index.vue?macro=true";
import { default as sellxMequOXpHKMeta } from "/opt/build/repo/client/pages/sell.vue?macro=true";
import { default as testx3fXNezv02Meta } from "/opt/build/repo/client/pages/test.vue?macro=true";
import { default as test2OoKLU12ShwMeta } from "/opt/build/repo/client/pages/test2.vue?macro=true";
import { default as _91verify_93euXn6cAhGGMeta } from "/opt/build/repo/client/pages/verify/[verify].vue?macro=true";
import { default as indexxjpePLvO58Meta } from "/opt/build/repo/client/pages/websites/[website]/components/index.vue?macro=true";
import { default as feedOTmYFD8i4iMeta } from "/opt/build/repo/client/pages/websites/[website]/content/[id]/feed.vue?macro=true";
import { default as filen1CtViS58YMeta } from "/opt/build/repo/client/pages/websites/[website]/content/[id]/file.vue?macro=true";
import { default as folder97zRuMyQ19Meta } from "/opt/build/repo/client/pages/websites/[website]/content/[id]/folder.vue?macro=true";
import { default as layoutsZdOYiifdnMeta } from "/opt/build/repo/client/pages/websites/[website]/content/[id]/layout.vue?macro=true";
import { default as indexMyFxXAPJBxMeta } from "/opt/build/repo/client/pages/websites/[website]/content/index.vue?macro=true";
import { default as indexts9L2mjP4CMeta } from "/opt/build/repo/client/pages/websites/[website]/extensions/index.vue?macro=true";
import { default as indexvR6nvCD1f1Meta } from "/opt/build/repo/client/pages/websites/[website]/redirects/index.vue?macro=true";
import { default as indexEJ0GDOZtGzMeta } from "/opt/build/repo/client/pages/websites/[website]/settings/index.vue?macro=true";
import { default as githubopettWhWXRMeta } from "/opt/build/repo/client/pages/websites/github.vue?macro=true";
import { default as indexiF2xq9NgOkMeta } from "/opt/build/repo/client/pages/websites/index.vue?macro=true";
import { default as setupRnDVp1TLoWMeta } from "/opt/build/repo/client/pages/websites/setup.vue?macro=true";
import { default as websites6gFIwkJGiLMeta } from "/opt/build/repo/client/pages/websites.vue?macro=true";
export default [
  {
    name: "-billing",
    path: "/-billing",
    component: () => import("/opt/build/repo/client/pages/-billing/index.vue").then(m => m.default || m)
  },
  {
    name: "-billing-plan",
    path: "/-billing/plan",
    component: () => import("/opt/build/repo/client/pages/-billing/plan.vue").then(m => m.default || m)
  },
  {
    name: "-billing-success",
    path: "/-billing/success",
    component: () => import("/opt/build/repo/client/pages/-billing/success.vue").then(m => m.default || m)
  },
  {
    name: "-billing-usage",
    path: "/-billing/usage",
    component: () => import("/opt/build/repo/client/pages/-billing/usage.vue").then(m => m.default || m)
  },
  {
    name: "-people",
    path: "/-people",
    component: () => import("/opt/build/repo/client/pages/-people/index.vue").then(m => m.default || m)
  },
  {
    name: "-team-all",
    path: "/-team/all",
    component: () => import("/opt/build/repo/client/pages/-team/all.vue").then(m => m.default || m)
  },
  {
    name: "-team-current",
    path: "/-team/current",
    component: () => import("/opt/build/repo/client/pages/-team/current.vue").then(m => m.default || m)
  },
  {
    name: "-team",
    path: "/-team",
    component: () => import("/opt/build/repo/client/pages/-team/index.vue").then(m => m.default || m)
  },
  {
    name: "-team-manage",
    path: "/-team/manage",
    component: () => import("/opt/build/repo/client/pages/-team/manage.vue").then(m => m.default || m)
  },
  {
    name: "-team-settings",
    path: "/-team/settings",
    component: () => import("/opt/build/repo/client/pages/-team/settings.vue").then(m => m.default || m)
  },
  {
    name: accountqlXoB4whbsMeta?.name,
    path: "/account",
    component: () => import("/opt/build/repo/client/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-change",
    path: "change",
    component: () => import("/opt/build/repo/client/pages/account/change.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("/opt/build/repo/client/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-plan",
    path: "plan",
    component: () => import("/opt/build/repo/client/pages/account/plan.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "settings",
    component: () => import("/opt/build/repo/client/pages/account/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: adminrLOo26hEJPMeta?.name,
    path: "/admin",
    component: () => import("/opt/build/repo/client/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-backup",
    path: "backup",
    component: () => import("/opt/build/repo/client/pages/admin/backup.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/opt/build/repo/client/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-themes",
    path: "themes",
    component: () => import("/opt/build/repo/client/pages/admin/themes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: branduGDsjlwSMWMeta?.name,
    path: "/brand",
    component: () => import("/opt/build/repo/client/pages/brand.vue").then(m => m.default || m),
    children: [
  {
    name: "brand-assets",
    path: "assets",
    component: () => import("/opt/build/repo/client/pages/brand/assets.vue").then(m => m.default || m)
  },
  {
    name: "brand-colors",
    path: "colors",
    component: () => import("/opt/build/repo/client/pages/brand/colors.vue").then(m => m.default || m)
  },
  {
    name: "brand-designer",
    path: "designer",
    component: () => import("/opt/build/repo/client/pages/brand/designer.vue").then(m => m.default || m)
  },
  {
    name: "brand-fonts",
    path: "fonts",
    component: () => import("/opt/build/repo/client/pages/brand/fonts.vue").then(m => m.default || m)
  },
  {
    name: "brand-icons",
    path: "icons",
    component: () => import("/opt/build/repo/client/pages/brand/icons.vue").then(m => m.default || m)
  },
  {
    name: "brand",
    path: "",
    component: () => import("/opt/build/repo/client/pages/brand/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/opt/build/repo/client/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: "email",
    path: "/email",
    component: () => import("/opt/build/repo/client/pages/email.vue").then(m => m.default || m)
  },
  {
    name: formsr0Bpms0npvMeta?.name,
    path: "/forms",
    component: () => import("/opt/build/repo/client/pages/forms.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-form",
    path: ":form()",
    component: () => import("/opt/build/repo/client/pages/forms/[form].vue").then(m => m.default || m)
  },
  {
    name: "forms",
    path: "",
    component: () => import("/opt/build/repo/client/pages/forms/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginJmYpJqBwezMeta || {},
    component: () => import("/opt/build/repo/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mediaP4wElF4O4wMeta?.name,
    path: "/media",
    component: () => import("/opt/build/repo/client/pages/media.vue").then(m => m.default || m),
    children: [
  {
    name: "media",
    path: "",
    component: () => import("/opt/build/repo/client/pages/media/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/build/repo/client/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerVe2scXsUVUMeta || {},
    component: () => import("/opt/build/repo/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-reset",
    path: "/reset/:reset()",
    meta: _91reset_93igxrv9Zlc4Meta || {},
    component: () => import("/opt/build/repo/client/pages/reset/[reset].vue").then(m => m.default || m)
  },
  {
    name: "reset",
    path: "/reset",
    meta: index5Dbg7FMMRuMeta || {},
    component: () => import("/opt/build/repo/client/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: sellxMequOXpHKMeta?.name,
    path: "/sell",
    component: () => import("/opt/build/repo/client/pages/sell.vue").then(m => m.default || m),
    children: [
  {
    name: "sell-customers",
    path: "customers",
    component: () => import("/opt/build/repo/client/pages/sell/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "sell",
    path: "",
    component: () => import("/opt/build/repo/client/pages/sell/index.vue").then(m => m.default || m)
  },
  {
    name: "sell-payment",
    path: "payment",
    component: () => import("/opt/build/repo/client/pages/sell/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "sell-products",
    path: "products",
    component: () => import("/opt/build/repo/client/pages/sell/products/index.vue").then(m => m.default || m)
  },
  {
    name: "sell-transactions",
    path: "transactions",
    component: () => import("/opt/build/repo/client/pages/sell/transactions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "test",
    path: "/test",
    meta: testx3fXNezv02Meta || {},
    component: () => import("/opt/build/repo/client/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test2",
    path: "/test2",
    meta: test2OoKLU12ShwMeta || {},
    component: () => import("/opt/build/repo/client/pages/test2.vue").then(m => m.default || m)
  },
  {
    name: "verify-verify",
    path: "/verify/:verify()",
    meta: _91verify_93euXn6cAhGGMeta || {},
    component: () => import("/opt/build/repo/client/pages/verify/[verify].vue").then(m => m.default || m)
  },
  {
    name: websites6gFIwkJGiLMeta?.name,
    path: "/websites",
    component: () => import("/opt/build/repo/client/pages/websites.vue").then(m => m.default || m),
    children: [
  {
    name: "websites-website-components",
    path: ":website()/components",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/components/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-content-id-feed",
    path: ":website()/content/:id()/feed",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/content/[id]/feed.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-content-id-file",
    path: ":website()/content/:id()/file",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/content/[id]/file.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-content-id-folder",
    path: ":website()/content/:id()/folder",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/content/[id]/folder.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-content-id-layout",
    path: ":website()/content/:id()/layout",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/content/[id]/layout.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-content",
    path: ":website()/content",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-extensions",
    path: ":website()/extensions",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/extensions/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-redirects",
    path: ":website()/redirects",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/redirects/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-website-settings",
    path: ":website()/settings",
    component: () => import("/opt/build/repo/client/pages/websites/[website]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-github",
    path: "github",
    meta: githubopettWhWXRMeta || {},
    component: () => import("/opt/build/repo/client/pages/websites/github.vue").then(m => m.default || m)
  },
  {
    name: "websites",
    path: "",
    component: () => import("/opt/build/repo/client/pages/websites/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-setup",
    path: "setup",
    component: () => import("/opt/build/repo/client/pages/websites/setup.vue").then(m => m.default || m)
  }
]
  }
]