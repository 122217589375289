// const dateFormat = require('dateformat')
import dateFormat from "dateformat";

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      datify: (value) => {
        return dateFormat(value, "mm.dd.yy");
      },
      dateDay: (value) => {
        return dateFormat(value, "mmmm dS");
      },
      dateDayTime: (value) => {
        return dateFormat(value, "mmmm dS, h:MM TT");
      },
      dateDayYear: (value) => {
        return dateFormat(value, "mmmm dS, yyyy");
      },
      dateDayYearTime: (value) => {
        return dateFormat(value, "mm/dd/yy h:MM TT");
      },
      dateTime: (value) => {
        return dateFormat(value, "dddd, mmmm dS, yyyy, h:MM TT");
        // return dateFormat(value, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
      },
      centsToDollars: (value) => {
        return (value / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
      centsToDollarsNoSign: (value) => {
        return (value / 100).toFixed(2);
      },
      formatNumber: (value) => {
        if (typeof value !== "number") {
          // value = parseFloat(value)
          value = Number(value);
        }
        return value.toLocaleString();
      },
      capitalize: (value) => {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
  };
});
